<ng-template #profileTemplate>
  <div class="scroll-page">
    <div *ngIf="user" class="col mx-1 mx-md-auto col-md-9 section-content">
      <div class="row">
        <m-card class="col-12 mt-1 card-custom" [type]="'custom'">
          <div class="card-custom-content">
            <div *ngIf="!this.userNameInitials; else userInitials" class="float-start m-4 d-none d-sm-block userbudget">
              <m-icon iconName="singleNeutralCircle2Bold"> </m-icon>
            </div>
            <ng-template #userInitials>
              <div class="float-start m-4 d-none d-sm-block userbudget">
                <div class="userbudget-initials">
                  <span>{{ this.userNameInitials }}</span>
                </div>
              </div>
            </ng-template>
            <div class="card-custom-body m-2">
              <div class="row">
                <p class="col-md-6 col-xs-12">
                  <b>{{ 'PERSON.NAME' | mTranslate }}:</b> {{ fullName }}
                </p>
                <p class="col-md-6 col-xs-12">
                  <b>{{ 'PERSON.BIRTHDAY' | mTranslate }}:</b> {{ (user.birthDate ? user.birthDate : '') | mDate }}
                </p>
              </div>
              <div class="row">
                <p class="col-md-6 col-xs-12">
                  <b> {{ 'PERSON.EMAIL' | mTranslate }}:</b> {{ user.email }}
                </p>
                <p class="col-md-6 col-xs-12">
                  <b>{{ 'PERSON.PHONE' | mTranslate }}:</b>
                  {{ user.phoneNumberPrefix ? '(' + user.phoneNumberPrefix + ')' : '' }}
                  {{ user.phoneNumber }}
                </p>
              </div>
              <div class="row">
                <p class="col-md-6 col-xs-12">
                  <b>{{ 'COMMON.SOCIETY' | mTranslate }}:</b> {{ user.society?.description }}
                </p>
                <p class="col-md-6 col-xs-12">
                  <b>{{ 'USER_PROFILE.DEPARTMENT' | mTranslate }}:</b> {{ departmentName }}
                </p>
              </div>
              <span>
                <b>{{ 'USER_PROFILE.COST_CENTER' | mTranslate }}:</b> {{ user.costCenterCode }}
              </span>
            </div>
          </div>
        </m-card>
      </div>
      <form *ngIf="userFormGroup" [formGroup]="userFormGroup">
        <div class="row mt-3">
          <h3 class="my-0 font-heading05-regular pb-2">{{ 'USER_PROFILE.DOCUMENTS' | mTranslate }}</h3>
          <div class="col col-md-6 col-12 px-1">
            <m-form-field label="{{ 'PERSON.DOCUMENT' | mTranslate }}*">
              <input
                m-input
                disabled="true"
                [placeholder]="'PERSON.DOCUMENT' | mTranslate"
                [value]="user.identityDocumentNumber || ''" />
            </m-form-field>
            <m-form-field label="{{ 'PERSON.PASSPORT' | mTranslate }}">
              <input m-input formControlName="passportDocumentNumber" [placeholder]="'PERSON.PASSPORT' | mTranslate" />
              <m-error>{{ 'COMMON.INVALID_FORMAT' | mTranslate }}</m-error>
            </m-form-field>
          </div>
          <div class="col col-md-6 col-12 px-1">
            <m-form-field label="{{ 'PERSON.DOCUMENT_EXPIRATION_DATE' | mTranslate }}*">
              <m-datepicker
                formControlName="identityDocumentExpirationDate"
                selectionMode="single"
                enableManualInput
                [min]="today">
              </m-datepicker>
            </m-form-field>
            <m-form-field label="{{ 'PERSON.PASSPORT_EXPIRATION_DATE' | mTranslate }}">
              <m-datepicker
                formControlName="passportDocumentExpirationDate"
                selectionMode="single"
                enableManualInput
                [min]="today">
              </m-datepicker>
            </m-form-field>
          </div>
        </div>

        <div formGroupName="notifications" class="row mt-1">
          <h3 class="my-0 font-heading05-regular pb-2">{{ 'USER_PROFILE.NOTIFICATIONS' | mTranslate }}</h3>
          <div class="col col-md-6 col-12 px-1">
            <m-form-field label="{{ 'PERSON.EMAIL_TRAVEL' | mTranslate }}*">
              <input m-input formControlName="emailTravel" [placeholder]="'PERSON.EMAIL_TRAVEL' | mTranslate" />
            </m-form-field>
          </div>
          <div class="col col-md-6 col-12 px-1 row">
            <div class="col col-md-4 col-12">
              <m-form-field class="pe-md-1" [label]="'PERSON.PHONE_PREFIX_TRAVEL' | mTranslate">
                <m-select
                  formControlName="phoneNumberTravelPrefix"
                  labelKey="label"
                  valueKey="codeNum"
                  placeholder="+34"
                  [options]="phonePrefixes"
                  [search]="true"></m-select>
              </m-form-field>
            </div>
            <div class="col col-md-8 col-12">
              <m-form-field class="full-width" [label]="'PERSON.PHONE_TRAVEL' | mTranslate">
                <input m-input formControlName="phoneNumberTravel" [placeholder]="'PERSON.PHONE_TRAVEL' | mTranslate" />
                <m-error
                  *ngIf="
                    this.userFormGroup.controls.notifications.get('phoneNumberTravel')?.hasError('minValidCharLength')
                  "
                  >{{ 'COMMON.MIN_LENGTH' | mTranslate: { length: 3 } }}</m-error
                >
              </m-form-field>
            </div>
          </div>
        </div>

        <div class="row mt-1">
          <h3 class="my-0 font-heading05-regular pb-2">{{ 'USER_PROFILE.LANGUAGE' | mTranslate }}</h3>
          <div class="col-md-6 col-12">
            <m-form-field class="px-1" [label]="'USER_PROFILE.LANGUAGE' | mTranslate">
              <m-select
                formControlName="language"
                labelKey="value"
                valueKey="key"
                [options]="languages"
                [placeholder]="'USER_PROFILE.SELECT_LANGUAGE' | mTranslate"></m-select>
            </m-form-field>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="row section-footer">
    <div class="col section-margin">
      <div class="d-flex align-items-center">
        <div class="d-flex full-width justify-content-end">
          <button
            class="me-2"
            m-button
            color="main"
            buttonType="flat"
            [disabled]="userFormGroup && !userFormGroup.valid"
            (click)="handleUpdate()">
            {{ 'USER_PROFILE.UPDATE' | mTranslate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<div *ngIf="isLoading; else profileTemplate" class="col mx-0 mx-sm-3 mx-md-auto col-md-9 section-content">
  <m-spinner></m-spinner>
</div>
