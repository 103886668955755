import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { APP_CONFIG } from '@constants';
import { UserProfilePageRoutingModule } from '@pages/user-profile/user-profile-page-routing.module';

import { MTranslateModule } from '@mercadona/core/translate';

import { UserProfilePageComponent } from './user-profile-page.component';
import { MercadonaModule } from '../../mercadona.module';

@NgModule({
  declarations: [UserProfilePageComponent],
  imports: [
    ReactiveFormsModule,
    FormsModule,
    UserProfilePageRoutingModule,
    MercadonaModule,
    MTranslateModule.forRoot(APP_CONFIG.language),
    CommonModule
  ]
})
export class UserProfilePageModule {}
